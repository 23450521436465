import { ParentWindowService } from "@parent-window/parent-window";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iNewsFeedItem } from "@sdk/services/newsfeed/newsfeed-models";
import classNames from "classnames";
import { UserAvatar } from "components/common/user-avatar/user-avatar";
import { useNavigator } from "helpers/hooks/use-navigator";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { loadNewsfeedItemById } from "store/modules/newsfeed-items/newsfeed-items.helpers";
import { selectNewsfeedItemById } from "store/modules/newsfeed-items/newsfeed-items.selectors";
import { selectWidget } from "store/modules/session/session.selectors";
import { loadUserBydId } from "store/modules/users/users.helpers";
import { selectUserById } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";
import { truncateHTML } from "utils/truncate-html";
import { HomePageWidgetContainer } from "views/home-page/sections/home-page-widget-container";

export const NewsfeedItemCard = ({
  record,
  mode = "CARD",
  onInteracted,
  truncatedView,
}: {
  record: iNewsFeedItem;
  mode?: "PAGE" | "CARD";
  onInteracted?: () => any;
  truncatedView?: boolean;
}) => {
  const [showFullContent, setShowFullContent] = useState(!truncatedView);

  const MAX_CONTENT_LENGTH = 200; // Adjust this as per your requirement
  const truncatedContent = truncateHTML(record.body, MAX_CONTENT_LENGTH);

  const navigator = useNavigator();

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const onReadMore = useCallback(() => {
    navigator.navigateTo(`/newsfeed/${record.id}`);
    onInteracted && onInteracted();
    setTimeout(() => {
      ParentWindowService.maximizeWidget();
    }, 0);
  }, [navigator, onInteracted, record.id]);

  const [addedReaction, setAddedReaction] = useState("");

  const {
    doAction: reactToItemCall,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (reaction: string) =>
        SDK.reactToNewsfeedItem(record.id!, { reaction }),
      // successMessage: "Form has been submitted",
      failureMessage: "Something went wrong",
      defaultResponse: false,
    }),
    [record?.id]
  );

  const reactToItem = useCallback(
    (reaction: string) => {
      setAddedReaction(reaction);
      reactToItemCall(reaction);
      onInteracted && onInteracted();
    },
    [onInteracted, reactToItemCall]
  );

  const widget = useSelector(selectWidget);

  const { state: sharedBy } = useQueryWithStore(
    selectUserById(record?.senderId!),
    loadUserBydId(record?.senderId!, widget?.id!),
    [record?.senderId],
    !record?.senderId
  );

  const Content = useMemo(() => {
    return (
      <>
        {record.header && (
          <img
            className="w-full h-48 object-cover rounded-t-lg"
            src={record.header}
            alt="Feature"
          />
        )}

        {/* Tags */}
        {record.tags && record.tags.length > 0 && (
          <div className="flex space-x-2 mt-4">
            {record.tags.map((tag) => (
              <span
                className="bg-blue-200 text-blue-800 px-3 py-1 rounded-full text-xs font-semibold"
                key={tag}
              >
                {tag}
              </span>
            ))}
          </div>
        )}

        {/* Content */}
        <div className="mt-4">
          <h2 className="text-xl font-semibold">{record.title}</h2>
          {sharedBy && (
            <div className="flex items-center mt-2">
              <div className="mr-3">
                <UserAvatar size={30} userId={sharedBy?.id!} />
              </div>
              <span className="text-sm text-gray-600">
                Shared by {sharedBy.data.firstName}
              </span>
            </div>
          )}

          {/* Display truncated or full content */}
          <div className="mt-4 text-gray-800">
            <div
              dangerouslySetInnerHTML={{
                __html: showFullContent ? record.body : truncatedContent,
              }}
              className="raw-html-rendered-block"
            ></div>

            {/* Show Read More/Read Less button if content is truncated */}
            {record.body !== truncatedContent && (
              <button
                onClick={onReadMore}
                className="text-blue-500 mt-2 text-sm"
              >
                {showFullContent ? "Read less" : "Read more"}
              </button>
            )}
          </div>
        </div>

        {/* Reaction Section */}
        <div className="mt-6 border-t pt-4 text-center">
          {addedReaction ? (
            <>
              <p className="text-sm text-gray-600">
                You reacted {addedReaction}
              </p>
            </>
          ) : (
            <>
              <p className="text-sm text-gray-600">How would you rate this?</p>
              <div className="flex justify-center space-x-4 mt-2">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                {["💜", "😴", "👎", "🎉"].map((item) => (
                  <span
                    role="img"
                    key={item}
                    className={classNames(
                      "text-2xl cursor-pointer hover-bg-primary-100 rounded-lg p-2 transition-all",
                      {
                        "bg-primary-100": item === addedReaction,
                      }
                    )}
                    onClick={() => reactToItem(item)}
                  >
                    {item}
                  </span>
                ))}
              </div>
            </>
          )}
        </div>
      </>
    );
  }, [
    addedReaction,
    onReadMore,
    reactToItem,
    record.body,
    record.header,
    record.tags,
    record.title,
    sharedBy,
    showFullContent,
    truncatedContent,
  ]);

  if (mode === "PAGE") {
    return <div className="p-4">{Content}</div>;
  }

  return <HomePageWidgetContainer containerClass="px-6">{Content}</HomePageWidgetContainer>;
};

export const NewsfeedItemWithLoader = ({
  newsfeedItemId,
  mode,
  onInteracted,
  truncatedView,
}: {
  newsfeedItemId: string;
  mode?: "PAGE" | "CARD";
  onInteracted?: () => any;
  truncatedView?: boolean;
}) => {
  const { state: newsfeedItem } = useQueryWithStore(
    selectNewsfeedItemById(newsfeedItemId),
    loadNewsfeedItemById(newsfeedItemId)(),
    [newsfeedItemId],
    !newsfeedItemId
  );

  return (
    <NewsfeedItemCard
      record={newsfeedItem}
      mode={mode}
      onInteracted={onInteracted}
      truncatedView={truncatedView}
    />
  );
};
