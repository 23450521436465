import { BrowserWakeUpHandler } from "@libs/browser-wake-up-handler";
import {
  ParentWindowService,
  processLastIdentifiedUser,
} from "@parent-window/parent-window";
import { useEstablishParentWindowSync } from "@parent-window/use-establish-parent-window-sync";
import { SDK } from "@sdk";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import { ReferenceSignalHandler } from "@services/reference-signal-handler";
import { HandleSocketEvents } from "@socket-engine/handle-socket-events";
import { LoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import { CustomErrorBoundary } from "error-boundary";
import { GenerateColorTheme } from "helpers/generate-theme";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Provider, useSelector } from "react-redux";
import { setInit } from "store/modules/app-state/app-state.actions";
import {
  selectIsClientIPBlocked,
  selectPreInitConfig,
  selectSocketConnectionState,
  selectWidgetMaximizedAtLeastOnce,
  selectWidgetMaximizedState,
} from "store/modules/app-state/app-state.selectors";
import { CampaignsController } from "store/modules/app-state/campaigns.controller";
import { loadAllConversations } from "store/modules/conversations/conversations.helpers";
import {
  loadMessages,
  refreshAllLoadedConversationMessages,
} from "store/modules/messages/messages.helpers";
import {
  loadWidget,
  sesSessionInitialData,
} from "store/modules/session/session.helpers";
import {
  selectSession,
  selectWidget,
  selectWidgetColorPallet,
} from "store/modules/session/session.selectors";
import { store } from "store/store";
import { useQueryWithStore, useSimpleLoaderStore } from "store/store.hooks";
import { getPageReferer } from "utils/get-page-referer";
import { isInIframe } from "utils/is-in-iframe";
import { loadStyle } from "utils/load-style";
import { parseQuery } from "utils/parse-query";
import { useDebouncedValue } from "utils/use-debounced-value";
import { useLocalStorageStore } from "utils/use-local-storage-store";
import "./App.scss";
import { NotifyUnreadMessages } from "helpers/notify-unread-messages";
import { useNavigator } from "helpers/hooks/use-navigator";
import { reWatchAdditionalEntities } from "store/re-watch-additional-entitities";
import { ConnectedRouter } from "connected-react-router";
import { history } from "store/store";
import AppRootRouter from "app-router";

const App = ({
  widgetId,
  accessToken,

  isSandboxMode,
  sandboxConfig,
}: {
  widgetId: string;
  accessToken?: string;
  isSandboxMode?: boolean;

  sandboxConfig?: any;
}) => {
  const WIDGET_ID = widgetId;
  const ORGANIZATION_ID = widgetId.split("-")[0];

  const [isReady, setIsReady] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const isClientIPBlocked = useSelector(selectIsClientIPBlocked);
  //* Ideally this could be in the store as well
  const [sessionToken, setSessionToken] = useLocalStorageStore<string>(
    `CC_SESSION_TOKEN_${WIDGET_ID}`,
    accessToken || "",
    isSandboxMode
  );

  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    SocketConnector.firstToken = sessionToken;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************
   * LOADING WIDGET
   * 1) Init SDK
   * 2) Load Widget Config
   * 3) Connect to Socket and Load Session
   *  *
   ******************/

  // 1) Init SDK
  useEffect(() => {
    SDK.configure({ token: sessionToken });
  }, [sessionToken]);

  // 2) Load Widget Configurations
  const {
    state: widget,
    retry: reload,
    isLoading: isWidgetLoading,
    hasError: widgetHasError,
  } = useQueryWithStore(
    selectWidget,
    loadWidget(WIDGET_ID),
    [sessionToken],
    !sessionToken
  );

  // 3)  Init Socket Controller
  const preInitConfig = useSelector(selectPreInitConfig);

  useEffect(() => {
    const isReadyForSocketConnections = (() => {
      if (isInIframe()) {
        return !!preInitConfig;
      }
      return true;
    })();
    if (!isReadyForSocketConnections) {
      return;
    }

    const socketQueryData = {
      device: JSON.stringify({
        screenH: window.screen.height,
        screenW: window.screen.width,
        timezone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
        timezoneOffset: new Date().getTimezoneOffset(),
      }),
      referer: preInitConfig?.referer,
      landedUrl: preInitConfig?.currentPage?.url,
      landedPageTitle: preInitConfig?.currentPage?.title,
    };

    SocketConnector.connect({
      token: sessionToken,
      organizationId: ORGANIZATION_ID,
      widgetId: WIDGET_ID,
      store,
      isSandboxMode,
      sandboxConfig,
      socketQueryData,
    });

    // console.log('Connect Socket')
    const removeSocketListeners = HandleSocketEvents(
      SocketConnector.socket,
      (data) => {
        // console.log("Socket Resolution", data);
        Object.assign(SocketConnector.socket!.io!.opts!.query!, {
          accessToken: data.accessToken,
          existingSessionToken: data.accessToken,
        });

        SDK.configure({ token: data.accessToken });
        setSessionToken(data.accessToken);
        SocketConnector.lastToken = data.accessToken;
        ParentWindowService.sendMessage({
          type: "INIT",
          data: { accessToken: data.accessToken },
        });
        setTimeout(() => {
          setIsReady(true);
        }, 0);

        sesSessionInitialData(store, data);
        NotifyUnreadMessages(store);

        const referer = ReferenceSignalHandler.getReferer();
        // console.log('Session Resolved', data.session?.metaData, isInIframe(), referer,document.referrer);

        if (!isInIframe()) {
          // Update Referer
          if (!data.session?.metaData?.referer) {
            if (referer) {
              SDK.updateReferer({ url: referer }).catch((e) => {
                console.log("Error while updating referer", e);
              });
            }
          }
        } else {
          // Is in iframe
          if (data.session.metaData.createdTime + 10000 > Date.now()) {
            // New session
            if (!data.session?.metaData?.referer) {
              // referer record is not there
              ParentWindowService.sendMessage({
                type: "GET_REFERER",
                data: {},
              });
            }
          }
        }

        if (!data.session.device?.timezone) {
          try {
            SDK.updateDevice({
              screenH: window.screen.height,
              screenW: window.screen.width,
              timezone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
              timezoneOffset: new Date().getTimezoneOffset(),
            }).catch((e) => {
              console.log("Error while updating referer", e);
            });
          } catch (e) {
            // Ignore Errors
          }
        }
      }
    );
    return removeSocketListeners;
  }, [
    ORGANIZATION_ID,
    WIDGET_ID,
    isSandboxMode,
    preInitConfig,
    sandboxConfig,
    sessionToken,
    setSessionToken,
  ]);

  const session = useSelector(selectSession);

  /******************
   * INIT WIDGET
   * 0) Set App Init
   * 1) Init Parent Window Sync
   * 2) Load Language
   * 3) Load Theme
   * 4) Process User Identity Records
   * 5) Handle Inactive Widgets
   * 6) Handle Blocked Clients
   * 7) Init Campaigns Controller
   *  *
   ******************/

  // 0) Set App Init
  useEffect(() => {
    // console.log("Set Init Time");
    store.dispatch(setInit(true));
  }, []);

  // 1) Init Parent Window Sync
  useEstablishParentWindowSync();

  // 2) Load Language
  useEffect(() => {
    const widgetLanguage = widget?.configurations.locale.language || "en";
    if (widgetLanguage !== "en") {
      i18n.changeLanguage(widgetLanguage);
    }
  }, [i18n, widget?.configurations?.locale?.language]);

  // 3) Load Theme
  const colorPallet = useSelector(selectWidgetColorPallet);
  useEffect(() => {
    loadStyle(
      GenerateColorTheme(colorPallet.primary, "#333"),
      "cc_globalTheme"
    );
  }, [colorPallet, widget]);

  // 4) Process User Identity Records
  // When session is loaded, process user.identify again - This is needed when session has been cleared from last identify call
  useEffect(() => {
    if (session?.id && widget?.id) {
      processLastIdentifiedUser(widget?.id, session);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.id, widget?.id]);

  // 5) Handle Inactive Widgets
  useEffect(() => {
    if (widget && !widget.metaData.isActive) {
      setIsBlocked(true);
      ParentWindowService.sendMessage({
        type: "WIDGET_DISABLED",
        data: widget,
      });
    }
  }, [widget]);

  // 6) Handle Blocked Clients
  useEffect(() => {
    if (widget && session && !isBlocked) {
      const blackListedIps =
        widget?.configurations?.blackListedClients?.ips || [];
      const clientIp = session.ip;
      if (blackListedIps.includes(clientIp)) {
        setIsBlocked(true);
        ParentWindowService.sendMessage({
          type: "CLIENT_BLOCKED",
          data: widget,
        });
      } else {
        const whiteListedCountries =
          widget?.configurations?.blackListedClients?.whiteListedCountries ||
          [];

        if (whiteListedCountries?.length > 0) {
          // Check Country
          if (
            !whiteListedCountries.includes(
              session?.location?.data?.country || ""
            )
          ) {
            setIsBlocked(true);
            ParentWindowService.sendMessage({
              type: "CLIENT_BLOCKED",
              data: widget,
            });
          }
        }
      }
    }
  }, [session, widget, isBlocked]);

  // 7) Init Campaigns Controller (and Handle Sandbox Configs)
  useEffect(() => {
    if (session?.id && widget?.id) {
      const parsedSandboxConfig = (() => {
        try {
          return sandboxConfig ? JSON.parse(sandboxConfig) : {};
        } catch (e) {
          return {};
        }
      })();
      if (parsedSandboxConfig?.chatBotId) {
        loadAllConversations(store)
          .then(() =>
            CampaignsController.triggerChatbotAsCampaign(
              parsedSandboxConfig.chatBotId
            )
          )
          .catch((e) => {
            console.log("Error while starting sandbox chatbot", e);
          });
      } else {
        CampaignsController.init();
      }
    }
  }, [sandboxConfig, session?.id, widget?.id]);

  const socketConnectionStatus = useSelector(selectSocketConnectionState);

  useEffect(() => {
    BrowserWakeUpHandler.init(async (sleepTime) => {
      if (sleepTime > 3 * 10000) {
        refreshAllLoadedConversationMessages(store, true).catch((e) => {
          console.log("Error while refreshing conversations");
        });
        reWatchAdditionalEntities();
      }
    });
  }, []);

  if (isClientIPBlocked) {
    return (
      <div className="w-full h-full flex flex-row justify-center items-center">
        {/* <img src="/logo.png" style={{ height: 40, marginRight: 5 }} alt="cc" /> */}
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <div className="text-gray-600">
          Access denied (Too many requests for IP)
        </div>
      </div>
    );
  }

  if ((!widget && isWidgetLoading) || !isReady) {
    return (
      <div className="h-full w-full flex flex-row justify-center items-center">
        <LoadingIndicator size={48} />
      </div>
    );
  }

  if (widgetHasError) {
    return (
      <div className="w-full h-full flex flex-row justify-center items-center">
        <img src="/logo.png" style={{ height: 40, marginRight: 5 }} alt="cc" />
        <div className="text-gray-600"> {t("WIDGET_HAS_ERROR")}</div>
      </div>
    );
  }

  if (isBlocked) {
    return (
      <div className="w-full h-full flex flex-row justify-center items-center">
        <img src="/logo.png" style={{ height: 40, marginRight: 5 }} alt="cc" />
        <div className="text-gray-600">{t("IS_BLOCKED")}</div>
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <>
        {!socketConnectionStatus && (
          <div className="disconnected">{t("LOADING_MESSAGE")}</div>
        )}
        <AppRootRouter />
      </>
    </div>
  );
};

const WrappedApp = () => {
  const {
    widgetId,
    accessToken,
    referer,
    isSandboxMode,
    sandboxConfig,
  } = useMemo(() => {
    const parsedUrl = parseQuery(
      // eslint-disable-next-line no-useless-escape
      window.location.href.replace(/^[^\?]+\??/, "")
    );
    const paths = window.location.pathname.split("/");
    // Todo: Check WIdget ID using isValidateWidgetId
    const widgetId: string =
      parsedUrl.widgetId ||
      (window as any).WIDGET_ID ||
      (paths[1] !== "undefined" ? paths[1] : undefined);
    const accessToken: string = parsedUrl.accessToken;
    (window as any).WIDGET_ID = widgetId;
    const referer = parsedUrl.referer || getPageReferer();
    return {
      widgetId,
      accessToken,
      referer,
      isSandboxMode: parsedUrl.isSandboxMode === "true",
      sandboxConfig: parsedUrl.sandboxConfig,
    };
  }, []);

  useEffect(() => {
    ReferenceSignalHandler.markReferer(referer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInIframe()) {
      document.body.classList.add("body-bg");
    }
  }, []);

  useEffect(() => {
    ParentWindowService.init({ WIDGET_ID: widgetId });
    ParentWindowService.sendMessage({
      type: "GET_PRE_INIT_CONFIG",
      data: {},
    });
  }, [widgetId]);

  const { t } = useTranslation("common");

  if (!widgetId) {
    return (
      <div className="w-full h-full flex flex-row justify-center items-center">
        <img src="/logo.png" style={{ height: 40, marginRight: 5 }} alt="" />
        <div className="text-gray-600"> {t("NO_WIDGET_ID_FOUND")}</div>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <CustomErrorBoundary>
        <App
          widgetId={widgetId}
          accessToken={accessToken}
          isSandboxMode={isSandboxMode}
          sandboxConfig={sandboxConfig}
        />
      </CustomErrorBoundary>
    </Provider>
  );
};

export default WrappedApp;
