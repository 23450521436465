import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router";
import { selectNewsfeedItemById } from "store/modules/newsfeed-items/newsfeed-items.selectors";
import { loadTicketTypes } from "store/modules/session/session.helpers";
import {
  selectActivatedCheckLists,
  selectAllTicketTypes,
  selectWidget,
} from "store/modules/session/session.selectors";
import { useQueryWithStore, useSimpleLoaderStore } from "store/store.hooks";
import { CSSTransition } from "react-transition-group";
import "./checklists.scss";
import sumBy from "lodash/sumBy";
import classNames from "classnames";
import {
  iCheckListInstance,
  iCheckListStep,
} from "@sdk/services/checklists/checklists-models";
import {
  selectAllActiveUsers,
  selectUserById,
} from "store/modules/users/users.selectors";
import { loadUserBydId } from "store/modules/users/users.helpers";
import { UserAvatar } from "components/common/user-avatar/user-avatar";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { push } from "connected-react-router";
import { ParentWindowService } from "@parent-window/parent-window";
import { CheckMarkIcon } from "components/common/icons/check-mark";
import { useNavigator } from "helpers/hooks/use-navigator";
import { MDIIcon } from "components/common/mdi-icon";
import { mdiCheck } from "@mdi/js";

export const CheckListPage = () => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const checklistInstanceId = useMemo(() => {
    const pathArray = history.location.pathname.split("/");
    return pathArray[2];
  }, [history.location.pathname]);

  const allCheckLists = useSelector(selectActivatedCheckLists);

  const checklistInstance = useMemo(
    () => allCheckLists?.find((item) => item.id === checklistInstanceId),
    [allCheckLists, checklistInstanceId]
  );

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        <SimplePageHeader
          invert
          title={
            <div className="flex flex-row">
              <div className="title">
                {checklistInstance?.checkList.name || "Task"}
              </div>
            </div>
          }
          useNavigationPush={true}
        />
        <div className="page-body flex-1 overflow-hidden">
          <PageScrollContainer
            className=" h-full w-full flex-1 bg-white relative p-4"
            // style={{ borderRadius: "8px 8px 0 0" }}
          >
            <CheckList checklistInstanceId={checklistInstanceId} hideTitle />
          </PageScrollContainer>
        </div>
      </div>
    </div>
  );
};

export const CheckList = ({
  checklistInstanceId,
  hideTitle,
}: {
  checklistInstanceId: string;
  hideTitle?: boolean;
}) => {
  const allCheckLists = useSelector(selectActivatedCheckLists);

  const checklistInstance = useMemo(
    () => allCheckLists?.find((item) => item.id === checklistInstanceId),
    [allCheckLists, checklistInstanceId]
  );

  if (!checklistInstance) {
    return <></>;
  }

  // eslint-disable-next-line react/jsx-pascal-case
  return (
    <_CheckList checklistInstance={checklistInstance} hideTitle={hideTitle} />
  );
};

const _CheckList = ({
  checklistInstance,
  hideTitle,
}: {
  checklistInstance: iCheckListInstance;
  hideTitle?: boolean;
}) => {
  const [openStepIndex, setOpenStepIndex] = useState<number | null>(
    checklistInstance?.completedSteps?.length || 0
  );

  const toggleStep = (index: number) => {
    if (openStepIndex === index) {
      setOpenStepIndex(null);
    } else {
      setOpenStepIndex(index);
    }
  };

  const progress =
    Math.round(
      ((checklistInstance?.completedSteps?.length || 0) /
        (checklistInstance?.checkList?.steps?.length || 1)) *
        10000
    ) / 100;

  const timeLeft = sumBy(
    checklistInstance?.checkList.steps.slice(
      checklistInstance.completedSteps.length - 1
    ),
    (step) => step.duration
  );

  const nextStep =
    checklistInstance?.checkList?.steps?.[
      checklistInstance?.completedSteps?.length
    ];

  const widget = useSelector(selectWidget);

  const { state: sharedBy } = useQueryWithStore(
    selectUserById(checklistInstance?.sharedBy!),
    loadUserBydId(checklistInstance?.sharedBy!, widget?.id!),
    [checklistInstance?.sharedBy],
    !checklistInstance?.sharedBy
  );

  return (
    <>
      <div className="check-list-container">
        <div className={classNames("p-4", { "pt-0": hideTitle })}>
          {!hideTitle && (
            <h1 className="text-xl font-bold">
              {checklistInstance.checkList.name}
            </h1>
          )}

          {checklistInstance.checkList.description && (
            <p
              className={classNames("text-gray-600", {
                "mt-2": !hideTitle,
                "-mt-4": hideTitle,
              })}
            >
              {checklistInstance.checkList.description}
            </p>
          )}

          {sharedBy && (
            <div className="flex items-center my-2">
              <div className="mr-3">
                <UserAvatar size={30} userId={sharedBy?.id!} />
              </div>
              <span className="text-sm text-gray-600">
                Shared by {sharedBy.data.firstName}
              </span>
            </div>
          )}

          <div
            className={classNames({
              "mt-6": checklistInstance.checkList.description,
              "mt-0": !checklistInstance.checkList.description,
            })}
          >
            <p className="text-sm text-gray-600 mb-4">
              {checklistInstance?.completedSteps?.length} of{" "}
              {checklistInstance.checkList.steps.length} done • About {timeLeft}{" "}
              min left
            </p>
            <div className="w-full bg-gray-200 h-2 rounded-full">
              <div
                className="primary-bg h-2 rounded-full"
                style={{ width: "20%" }}
              ></div>
            </div>

            <div className="check-list-steps-container overflow-hidden">
              {/* box-shadow: 0px 0px 23px 0px #00000042; */}
              <div className="mt-12 space-y-4">
                {checklistInstance.checkList.steps.map((step, index) => (
                  <CheckListStep
                    key={index}
                    index={index}
                    openStepIndex={openStepIndex!}
                    checklistInstance={checklistInstance}
                    step={step}
                    toggleStep={toggleStep}
                    setOpenStepIndex={setOpenStepIndex}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {checklistInstance.isFullyCompleted && (
          <>
            <div className="p-6 rounded-lg text-center">
              <h1 className="text-2xl font-bold">Well done! 🎉</h1>
              <p className="text-gray-500 mt-2">
                You have completed all the tasks
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const CheckListStep = ({
  index,
  openStepIndex,
  checklistInstance,
  step,
  toggleStep,
  setOpenStepIndex,
}: {
  index: number;
  openStepIndex: number;
  checklistInstance: iCheckListInstance;
  step: iCheckListStep;
  toggleStep: (index: number) => any;
  setOpenStepIndex: (step: number) => any;
}) => {
  const { doAction: onMarkAsCompleted, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.checkLists
          .markStepAsCompleted(checklistInstance.id!, {
            stepId: step.id!,
          })
          .then((d) => {
            setOpenStepIndex(index + 1);
          }),
      // successMessage: "Ticket has been submitted",
      failureMessage: "Something went wrong",
      defaultResponse: false,
    }),
    [checklistInstance.id, index, setOpenStepIndex, step.id]
  );

  const store = useStore();
  const navigator = useNavigator();

  const onCTAClicked = useCallback(() => {
    if (step.action === "OPEN_ARTICLE") {
      // store.dispatch(push(`/knowledge-base/articles/${step.articleId}`));
      navigator.navigateTo(`/knowledge-base/articles/${step.articleId}`);
      ParentWindowService.sendMessage({ type: "OPEN_WIDGET" });
    } else if (step.action === "OPEN_TOUR") {
      ParentWindowService.sendMessage({
        type: "ACTIVATE_TOUR",
        data: {
          tourId: step.tourId,
        },
      });
    } else if (step.action === "OPEN_URL") {
      const win = window.open(step.url, "_blank");
      win && win.focus();
    } else if (step.action === "EMIT_EVENT") {
      ParentWindowService.sendMessage({
        type: "EMIT_CHECK_LIST_EVENT",
        data: {
          eventName: step.eventName,
        },
      });
    } else if (step.action === "NONE") {
      // const win = window.open(step.url, "_blank");
      // win && win.focus();
    }
  }, [
    navigator,
    step.action,
    step.articleId,
    step.eventName,
    step.tourId,
    step.url,
  ]);

  return (
    <div
      className={classNames("rounded-lg border border-gray-200", {
        "shadow-lg": index === openStepIndex,
      })}
    >
      <div
        className="flex items-center justify-between hover:bg-gray-100 cursor-pointer p-4"
        onClick={() => toggleStep(index)}
      >
        <div className="flex items-center">
          <div
            className={classNames(
              `w-8 h-8 flex items-center justify-center rounded-full font-semibold ${
                checklistInstance.completedSteps.includes(step.id!)
                  ? "bg-green-600"
                  : "bg-gray-200"
              }`,
              {
                "text-white": checklistInstance.completedSteps.includes(
                  step.id!
                ),
                // "text-primary": !checklistInstance.completedSteps.includes(
                //   step.id!
                // ),
              }
            )}
          >
            {index + 1}
          </div>
          <h3 className="ml-4 font-semibold text-gray-800">{step.title}</h3>
        </div>
        <div
          className={`transform transition-transform duration-300 ease-in-out ${
            openStepIndex !== index ? "rotate-180" : ""
          }`}
        >
          {openStepIndex === index ? (
            <svg
              fill="none"
              strokeWidth={1.5}
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 15.75 7.5-7.5 7.5 7.5"
              />
            </svg>
          ) : (
            <svg
              fill="none"
              strokeWidth={1.5}
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 15.75 7.5-7.5 7.5 7.5"
              />
            </svg>
          )}
        </div>
      </div>

      <CSSTransition
        in={openStepIndex === index}
        timeout={300}
        classNames="collapse"
        unmountOnExit
      >
        <div className="transition-all duration-300 p-4 pt-0">
          <p
            className="text-gray-700 raw-html-rendered-block"
            dangerouslySetInnerHTML={{
              __html: step.content,
            }}
          ></p>
          {step.actionText &&
            !checklistInstance.completedSteps.includes(step.id!) && (
              <button
                className="mt-4 primary-button px-4 py-2 rounded font-semibold"
                onClick={onCTAClicked}
              >
                {step.actionText}
              </button>
            )}

          {!checklistInstance.completedSteps.includes(step.id!) && (
            <>
              <div className="mt-4 pl-1 pt-2 border-t border-gray-200 flex flex-row justify-end items-center">
                <button
                  className="secondary-button p-2 rounded flex flex-row gap-2"
                  onClick={() => onMarkAsCompleted()}
                >
                  <MDIIcon icon={mdiCheck} size="1.3rem" />
                  Mark as completed
                </button>
              </div>
            </>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};
