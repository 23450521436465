import classNames from "classnames";
import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useNavigationAnimationPatch } from "utils/hooks/use-navigation-animation-patch";
import PerfectScrollbar from "react-perfect-scrollbar";

export const PageScrollContainer = ({ children, className, ...props }) => {
  let prefectScrollbarVisible = useNavigationAnimationPatch();
  // prefectScrollbarVisible = false;
  if (prefectScrollbarVisible) {
    return (
      <PerfectScrollbar
        {...props}
        className={classNames("page-overflow-container", className)}
        options={{ suppressScrollX: true }}
      >
        {children}
      </PerfectScrollbar>
    );
  }
  return (
    <div
      className={classNames("page-overflow-container", className)}
      {...props}
    >
      {children}
    </div>
  );
};

export const PerfectScrollbarContainer = PerfectScrollbar;
